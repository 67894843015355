<script setup>
useSeoMeta({
  title: "VendApp - O melhor app de vendas",
});
</script>

<template>
  <div>
    <NuxtPage />

    <UNotifications />
  </div>
</template>

<style>
* {
  font-family: "Inter";
}
</style>
