export default defineAppConfig({
  ui: {
    primary: "vendapp",
    grey: "cool",
    strategy: "override",
    notifications: {
      // Show toasts at the top right of the screen
      position: "top-0 md:right-0 bottom-auto",
    },
    card: {
      header: {
        background: "bg-primary",
        base: "rounded-t-lg font-bold text-white",
      },
    },
  },
});
